import React from "react";
import Banner from "../Banner";
import IndianSpicesImage from "../../assets/images/indian-spices.jpg";
import IndianGrainsAndBeansImage from "../../assets/images/indian-grains-beans.jpg";
import OilSeedsImage from "../../assets/images/oil-seeds.jpg";
import { Box, Typography, styled } from "@mui/material";

const ProductsSection = () => {
  const BannerSubTitle = styled(Typography)`
    font-weight: 500;
    font-family: var(--font-primary);
    font-size: 28px;
    line-height: 38px;
    color: #e36d00;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      border: solid 1.5px #e36d00;
      width: 54.5px;
      left: -64.5px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      border: solid 1.5px #e36d00;
      width: 54.5px;
      right: -64.5px;
    }
  `;

  const BannerTitle = styled(Typography)`
    font-weight: 700;
    padding-bottom: 1.25rem;
    font-family: var(--font-primary);
    text-align: center;
  `;

  const ProductContainer = styled(Box)`
    display: flex;
    align-items: center;
    flex-direction: column;
  `;

  return (
    <Box>
      <ProductContainer sx={{ padding: { xs: "0px 25px", md: "0px 50px" } }}>
        <BannerSubTitle>Our Products</BannerSubTitle>
        <BannerTitle
          variant="h1"
          sx={{
            fontSize: { xs: "30px", md: "54px" },
            lineHeight: { xs: "40px", md: "70.2px" },
          }}
        >
          Your Source for Quality Products
        </BannerTitle>
      </ProductContainer>
      <Box>
        <Banner
          bannerTitle="All Types of Indian Spices"
          bannerDescription="You can get every type of Indian Spices from us according your need."
          bannerImage={IndianSpicesImage}
        />
        <Banner
          bannerTitle="All Types of Indian Grains & Beans"
          bannerDescription="You can get every type of Indian Grains & Beans from us according your need."
          bannerImage={IndianGrainsAndBeansImage}
          imageAlignment="right"
        />
        <Banner
          bannerTitle="All Types of Oil & Seeds"
          bannerDescription="You can get every type of Oils & Seeds from us according your need."
          bannerImage={OilSeedsImage}
        />
      </Box>
    </Box>
  );
};

export default ProductsSection;
