import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AboutBannerImage from "../../assets/images/about-banner.jpg";

const AboutBanner = () => {
  const BannerImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `;

  const BannerContainer = styled(Grid)`
    min-height: 75vh;
    height: 100%;
  `;

  const BannerContent = styled(Grid)`
    margin: 0 auto;
    display: flex;
    align-items: center;
  `;

  const BannerSubTitle = styled(Typography)`
    font-weight: 500;
    font-family: var(--font-primary);
    font-size: 28px;
    line-height: 38px;
    color: #e36d00;
    position: relative;
    padding-left: 64.5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      border: solid 1.5px #e36d00;
      width: 54.5px;
      left: 0;
    }
  `;

  const BannerTitle = styled(Typography)`
    font-weight: 700;
    padding-bottom: 1.25rem;
    font-family: var(--font-primary);
  `;

  const BannerDescription = styled(Typography)`
    font-family: var(--font-primary);
    font-weight: 400;
    line-height: 28px;
    font-size: 18px;
  `;

  return (
    <div>
      <div>
        <BannerContainer
          container
          sx={{
            padding: { xs: "25px", md: "50px 50px" },
          }}
        >
          <Grid item xs={12} md={6}>
            <BannerImage
              sx={{
                maxHeight: { xs: "280px", md: "619px" },
              }}
              src={AboutBannerImage}
              alt="treatment-image"
              width="100%"
              effect="blur"
            />
          </Grid>
          <BannerContent item xs={12} md={6}>
            <Box
              sx={{
                width: { sx: "100%", md: "80%" },
                margin: "0 auto",
              }}
            >
              <BannerSubTitle>About Us</BannerSubTitle>
              <BannerTitle
                variant="h1"
                sx={{
                  fontSize: { xs: "35px", md: "54px" },
                  lineHeight: { xs: "45px", md: "70.2px" },
                }}
              >
                Your Global Trade Specialists
              </BannerTitle>
              <BannerDescription
                sx={{ paddingBottom: { xs: "2rem", md: "3rem" } }}
              >
                At VK Brothers, we are your trusted global trade specialists,
                providing comprehensive solutions that streamline import-export
                operations and unlock global opportunities. <br />
                <br /> In today's dynamic global economy, navigating the
                intricacies of import and export is crucial for businesses
                seeking to expand their reach and achieve sustainable growth.
              </BannerDescription>
            </Box>
          </BannerContent>
        </BannerContainer>
      </div>
    </div>
  );
};

export default AboutBanner;
