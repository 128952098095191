import * as React from "react";

const VKLogo = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 48, height = 45, color = "#111111" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7818 0H0L22.6909 44.5091L28.8 33.1636L11.7818 0Z"
        fill={color}
      />
      <path
        d="M45.3818 0H33.6L24.4364 19.6364L37.0909 44.5091H48L35.3455 19.6364L45.3818 0Z"
        fill={color}
      />
    </svg>
  );
};
export default VKLogo;
