import React from "react";
import { Box, Divider, Grid, Typography, styled } from "@mui/material";
import WhatsappIcon from "../../assets/images/Whatsapp";

const ContactUs = () => {
  const Container = styled(Box)`
    background: #1e2326;
  `;

  const ContactTitle = styled(Box)`
    font-weight: 700;
    color: #ffffff;
  `;

  const ContactSubTitle = styled(Box)`
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #ffffff;
  `;

  const ContactDetails = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 18px;
  `;

  const ContactDetailsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `;

  const ContactDetailsTitle = styled(Box)`
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #ffffff;
  `;

  const ContactDetailsDescription = styled(Box)`
    font-size: 22px;
    line-height: 26.4px;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    & a {
      font-size: 22px;
      line-height: 26.4px;
      font-weight: 500;
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  `;

  const OurOfficeText = styled(Typography)`
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
    color: #ffffff;
    padding-bottom: 20px;
  `;

  const NumberContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 15px;
  `;

  return (
    <Container sx={{ padding: { xs: "25px", sm: "50px" } }}>
      <Box>
        <ContactTitle
          sx={{
            fontSize: { xs: "30px", md: "48px" },
            lineHeight: { xs: "40px", md: "60px" },
          }}
        >
          Together with you we can do more
        </ContactTitle>
        <ContactSubTitle>
          You can connect with us when need help!
        </ContactSubTitle>
      </Box>
      <Divider color="#ffffff" sx={{ margin: "40px 0px" }} />
      <Grid container>
        <ContactDetails item xs={12} md={5}>
          <ContactDetailsContainer>
            <ContactDetailsTitle>Phone Number </ContactDetailsTitle>
            <ContactDetailsDescription>
              <NumberContainer>
                <a href="https://wa.me/+917575064288" target="_blank">
                  <WhatsappIcon />
                </a>
                <a href="tel:+917575064288">+91 75750 64288</a>
              </NumberContainer>
              <NumberContainer>
                <a href="https://wa.me/+91972 3528721" target="_blank">
                  <WhatsappIcon />
                </a>
                <a href="tel:+91972 3528721">+91 97235 28721</a>
              </NumberContainer>
            </ContactDetailsDescription>
          </ContactDetailsContainer>
          <ContactDetailsContainer>
            <ContactDetailsTitle>Our Email</ContactDetailsTitle>
            <ContactDetailsDescription>
              <a href="mailto:vkbrothersexports@gmail.com">
                vkbrothersexports@gmail.com
              </a>
            </ContactDetailsDescription>
          </ContactDetailsContainer>
          <ContactDetailsContainer>
            <ContactDetailsTitle>Our Location</ContactDetailsTitle>
            <ContactDetailsDescription>
              42 Bhesan Marketing Yard, 362020, Gujarat, India.
            </ContactDetailsDescription>
          </ContactDetailsContainer>
        </ContactDetails>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ paddingTop: { xs: "25px", sm: "0px" } }}
        >
          <Box>
            <OurOfficeText>Our Office</OurOfficeText>
          </Box>
          <Box>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3710.683063964046!2d70.70207479999999!3d21.559244600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39580eb2d5c00fab%3A0x416a26003c96cb1b!2sSardar%20Vallabhbhai%20Marketing%20Yard%20Bhesan!5e0!3m2!1sen!2sin!4v1703336353144!5m2!1sen!2sin"
              width="100%"
              height="302px"
              style={{ border: "none" }}
              loading="lazy"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUs;
