import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ButtonComponent from "../Button";
import { handleContactUs } from "../../utils/utils";

interface IBanner {
  bannerImage: string;
  bannerTitle: string;
  bannerDescription: string;
  imageAlignment?: "left" | "right" | string;
}

const Banner = ({
  bannerImage,
  bannerTitle,
  bannerDescription,
  imageAlignment = "left",
}: IBanner) => {
  const TreatmentBannerImage = styled(LazyLoadImage)`
    width: 100%;
    height: 100%;
  `;

  const TreatmentBannerContaier = styled(Grid)`
    min-height: 75vh;
    height: 100%;
  `;

  const TreatmentBannerContent = styled(Grid)`
    margin: 0 auto;
    display: flex;
    align-items: center;
  `;

  const TreatmentBannerTitle = styled(Typography)`
    font-weight: 500;
    padding-bottom: 1.25rem;
    font-family: var(--font-primary);
  `;

  const TreatmentBannerDescription = styled(Typography)`
    font-family: var(--font-secondary);
    font-weight: 400;
    line-height: 28px;
    font-size: 18px;
  `;
  return (
    <div>
      <TreatmentBannerContaier
        container
        sx={{
          padding: { xs: "0", md: "50px 50px" },
          flexDirection: {
            xs: "column",
            md: imageAlignment === "right" ? "row-reverse" : "row",
          },
        }}
      >
        <Grid item xs={12} md={7}>
          <TreatmentBannerImage
            sx={{
              objectFit: { xs: "cover", md: "cover" },
              height: { xs: "280px", md: "425px" },
            }}
            src={bannerImage}
            alt="treatment-image"
            width="100%"
            effect="blur"
          />
        </Grid>
        <TreatmentBannerContent item xs={12} md={5}>
          <Box
            sx={{
              width: { sx: "100%", md: "80%" },
              margin: "0 auto",
              padding: "25px",
            }}
          >
            <TreatmentBannerTitle
              variant="h1"
              sx={{
                fontSize: { xs: "35px", md: "40px" },
                lineHeight: { xs: "45px", md: "52px" },
              }}
            >
              {bannerTitle}
            </TreatmentBannerTitle>
            <TreatmentBannerDescription
              sx={{ paddingBottom: { xs: "2rem", md: "3rem" } }}
            >
              {bannerDescription}
            </TreatmentBannerDescription>
            <ButtonComponent
              label="Contact Us"
              onClick={handleContactUs}
            />
          </Box>
        </TreatmentBannerContent>
      </TreatmentBannerContaier>
    </div>
  );
};

export default Banner;
