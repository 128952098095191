import * as React from "react";
const VKBrothers = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 322, height = 36, color = "#111111" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 322 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M304.365 25.2502C305.215 26.7462 306.133 27.8762 307.119 28.6401C308.105 29.3722 309.21 29.7383 310.433 29.7383C311.623 29.7383 312.626 29.4359 313.442 28.8311C314.258 28.1945 314.666 27.4306 314.666 26.5393C314.666 26.0619 314.598 25.6481 314.462 25.298C314.326 24.916 314.105 24.5499 313.799 24.1998C313.493 23.8179 313.102 23.4359 312.626 23.0539C312.184 22.6401 311.657 22.1945 311.045 21.7171C309.346 20.3802 307.816 19.1706 306.456 18.0884C305.13 16.9744 304.008 15.908 303.09 14.8895C302.172 13.8709 301.458 12.8683 300.948 11.8815C300.472 10.8629 300.234 9.78072 300.234 8.63483C300.234 7.42528 300.489 6.29531 300.999 5.24491C301.543 4.19451 302.274 3.28735 303.192 2.52343C304.11 1.72767 305.181 1.10698 306.405 0.661355C307.629 0.215732 308.921 -0.00708008 310.28 -0.00708008C312.592 -0.00708008 314.564 0.51812 316.196 1.56852C317.862 2.58709 319.426 3.89213 320.888 5.48364L315.839 9.49425C314.887 8.47568 313.952 7.67992 313.034 7.10698C312.116 6.53404 311.164 6.24756 310.178 6.24756C309.261 6.24756 308.513 6.47037 307.935 6.916C307.357 7.36162 307.068 7.93457 307.068 8.63483C307.068 9.01679 307.153 9.38284 307.323 9.73297C307.527 10.0831 307.85 10.4969 308.292 10.9744C308.768 11.42 309.38 11.9452 310.128 12.5499C310.909 13.1229 311.878 13.8391 313.034 14.6985C314.292 15.6534 315.431 16.5606 316.451 17.42C317.505 18.2476 318.406 19.107 319.154 19.9982C319.902 20.8895 320.48 21.8444 320.888 22.8629C321.296 23.8497 321.5 24.9797 321.5 26.2529C321.5 27.6852 321.228 29.0062 320.684 30.2157C320.14 31.3934 319.375 32.412 318.389 33.2714C317.403 34.1308 316.213 34.7993 314.819 35.2767C313.459 35.7542 311.946 35.9929 310.28 35.9929C307.595 35.9929 305.3 35.3881 303.396 34.1786C301.492 32.9372 299.86 31.0592 298.5 28.5446L304.365 25.2502Z"
        fill={color}
      />
      <path
        d="M282.1 0.99292C286.03 0.99292 289.126 1.79793 291.387 3.40796C293.683 5.01799 294.83 7.49617 294.83 10.8425C294.83 15.2938 292.43 18.3086 287.63 19.8871L296.5 34.9929H288.726L280.326 20.5974H279.648V34.9929H272.5V0.99292H282.1ZM279.648 14.2993H282.935C286.204 14.2993 287.839 13.1313 287.839 10.7951C287.839 8.4906 286.135 7.33832 282.726 7.33832H279.648V14.2993Z"
        fill={color}
      />
      <path
        d="M244.5 34.9929V0.99292H265.5V7.29097H251.839V13.4943H265.5V19.6503H251.839V28.6475H265.5V34.9929H244.5Z"
        fill={color}
      />
      <path
        d="M210.5 34.9929V0.99292H217.403V13.7784H229.597V0.99292H236.5V34.9929H229.597V20.1238H217.403V34.9929H210.5Z"
        fill={color}
      />
      <path
        d="M184.5 0.99292H205.5V7.33832H198.518V34.9929H191.271V7.33832H184.5V0.99292Z"
        fill={color}
      />
      <path
        d="M143.5 17.969C143.5 15.4192 144.022 13.0607 145.066 10.8933C146.11 8.69412 147.52 6.7977 149.295 5.20408C151.07 3.57858 153.141 2.30368 155.508 1.37938C157.875 0.455072 160.381 -0.00708008 163.026 -0.00708008C165.602 -0.00708008 168.056 0.455072 170.388 1.37938C172.754 2.27181 174.825 3.53077 176.6 5.15627C178.41 6.74989 179.837 8.66224 180.882 10.8933C181.961 13.0925 182.5 15.4989 182.5 18.1124C182.5 20.6304 181.995 22.9889 180.986 25.1881C179.977 27.3555 178.602 29.236 176.861 30.8296C175.121 32.4232 173.068 33.6822 170.701 34.6065C168.334 35.5308 165.811 35.9929 163.131 35.9929C160.346 35.9929 157.753 35.5308 155.351 34.6065C152.985 33.6503 150.914 32.3595 149.139 30.734C147.398 29.1085 146.023 27.1961 145.014 24.9969C144.005 22.7977 143.5 20.4551 143.5 17.969ZM150.705 18.1124C150.705 19.8654 151.035 21.4591 151.697 22.8933C152.358 24.2957 153.246 25.5069 154.359 26.5268C155.508 27.5467 156.831 28.3435 158.327 28.9172C159.824 29.4591 161.408 29.73 163.078 29.73C164.784 29.73 166.367 29.4272 167.829 28.8216C169.326 28.216 170.614 27.3873 171.693 26.3356C172.807 25.2838 173.677 24.0567 174.303 22.6543C174.965 21.22 175.295 19.6901 175.295 18.0646C175.295 16.471 174.982 14.973 174.355 13.5706C173.764 12.1363 172.911 10.8774 171.797 9.79372C170.718 8.71005 169.413 7.8495 167.882 7.21204C166.385 6.57459 164.749 6.25587 162.974 6.25587C161.303 6.25587 159.72 6.54272 158.223 7.11643C156.726 7.69013 155.421 8.50288 154.307 9.55468C153.193 10.5746 152.306 11.8176 151.645 13.2838C151.018 14.718 150.705 16.3276 150.705 18.1124Z"
        fill={color}
      />
      <path
        d="M125.1 0.99292C129.03 0.99292 132.126 1.79793 134.387 3.40796C136.683 5.01799 137.83 7.49617 137.83 10.8425C137.83 15.2938 135.43 18.3086 130.63 19.8871L139.5 34.9929H131.726L123.326 20.5974H122.648V34.9929H115.5V0.99292H125.1ZM122.648 14.2993H125.935C129.204 14.2993 130.839 13.1313 130.839 10.7951C130.839 8.4906 129.135 7.33832 125.726 7.33832H122.648V14.2993Z"
        fill={color}
      />
      <path
        d="M85.5 0.99292H92.6715C94.5497 0.99292 96.2914 1.15077 97.8964 1.46646C99.5356 1.78215 100.936 2.28726 102.097 2.98178C103.292 3.6763 104.214 4.59181 104.863 5.7283C105.546 6.83322 105.888 8.17491 105.888 9.75336C105.888 11.1108 105.563 12.3105 104.914 13.3523C104.265 14.394 103.309 15.3727 102.046 16.2882C104.197 17.2353 105.802 18.4191 106.861 19.8397C107.954 21.2288 108.5 22.9651 108.5 25.0486C108.5 26.5639 108.193 27.9372 107.578 29.1684C106.997 30.3996 106.178 31.4414 105.119 32.2938C104.061 33.1461 102.78 33.8091 101.277 34.2826C99.8088 34.7561 98.2038 34.9929 96.4621 34.9929H85.5V0.99292ZM92.5178 20.0765V28.8369H94.8742C97.0939 28.8369 98.7673 28.537 99.8942 27.9372C101.055 27.3058 101.636 26.264 101.636 24.8119C101.636 23.2965 101.021 22.1285 99.7918 21.3077C98.5624 20.4869 96.7183 20.0765 94.2595 20.0765H92.5178ZM92.5178 7.14891V14.2993H94.0546C95.6938 14.2993 96.9232 13.9836 97.7428 13.3523C98.5965 12.7209 99.0234 11.8054 99.0234 10.6057C99.0234 9.50081 98.6307 8.64845 97.8452 8.04863C97.0598 7.44882 95.8816 7.14891 94.3107 7.14891H92.5178Z"
        fill={color}
      />
      <path
        d="M39.5 34.9929V0.99292H46.6272V14.252L57.396 0.99292H65.4075L52.4017 16.9038L66.5 34.9929H58.4364L46.6272 19.745V34.9929H39.5Z"
        fill={color}
      />
      <path
        d="M0.5 0.99292H7.93256L17.4209 25.1433L27.1202 0.99292H34.5L20.8473 34.9929H13.9419L0.5 0.99292Z"
        fill={color}
      />
    </svg>
  );
};
export default VKBrothers;
