import * as React from "react";

const Vector = (props: React.SVGProps<SVGSVGElement>) => {
  const { width = 19, height = 18, color = "#111111" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.36384 17.364L17.9808 0.746952M17.9808 0.746952H5.25293M17.9808 0.746952V13.4749"
        stroke={color}
        strokeWidth={1.5}
      />
    </svg>
  );
};
export default Vector;
