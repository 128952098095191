import React from "react";
import {
  Box,
  Typography,
  styled,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import VKLogo from "../../assets/images/VKLogo";
import "react-lazy-load-image-component/src/effects/blur.css";
import VKBrothers from "../../assets/images/VKBrothers";
import ImportExportLogo from "../../assets/images/ImprotExport";
import { handleAboutUs, handleProducts } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const FooterLogoContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  `;

  const CopyRightContainer = styled(Box)`
    background: #f1f1f1;
  `;

  const CopyRightText = styled(Typography)`
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: #898989;
    text-align: center;
  `;

  const ListContainer = styled(List)`
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  `;

  const ListItems = styled(ListItem)`
    text-decoration: none;
    color: #383838 !important;
    font-size: 18px;
    font-family: var(--font-secondary);
    font-weight: 500;
    cursor: pointer;
    line-height: 28px;
  `;

  const handleHomeClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer>
      <Box
        sx={{
          padding: { xs: "60px 25px", md: "60px 45px" },
        }}
      >
        <FooterLogoContainer>
          <VKLogo width={59} height={55} />
          <VKBrothers />
          <ImportExportLogo />
        </FooterLogoContainer>
        <Box>
          <ListContainer sx={{ gap: { xs: "0px", sm: "10px" } }}>
            <ListItems
              sx={{
                width: "max-content",
                padding: { md: "8px", lg: "8px 16px" },
              }}
              onClick={handleHomeClick}
            >
              Home
            </ListItems>
            <Divider
              orientation="vertical"
              sx={{ width: "1px", height: "15px", background: "#BFBFBF" }}
            />
            <ListItems
              sx={{
                width: "max-content",
                padding: { md: "8px", lg: "8px 16px" },
              }}
              onClick={handleAboutUs}
            >
              About Us
            </ListItems>
            <Divider
              orientation="vertical"
              sx={{ width: "1px", height: "15px", background: "#BFBFBF" }}
            />
            <ListItems
              sx={{
                width: "max-content",
                padding: { md: "8px", lg: "8px 16px" },
              }}
              onClick={handleProducts}
            >
              Products
            </ListItems>
          </ListContainer>
        </Box>
      </Box>

      <CopyRightContainer
        sx={{
          padding: { xs: "17px 25px", md: "17px 50px" },
        }}
      >
        <CopyRightText>
          © Copyright 2018 V.K. Brothers Exports All Rights Reserved
        </CopyRightText>
      </CopyRightContainer>
    </footer>
  );
};

export default Footer;
