export const handleAboutUs = () => {
  // Get a reference to the section you want to scroll to
  const section: HTMLElement | null = document.getElementById("about-us");
  const offsetTop = section !== null ? section.offsetTop - 106 : 0;
  // Scroll to the section

  if (section) {
    section.scrollIntoView({
      behavior: "smooth",
    });
    if (window.innerWidth < 900) {
      window.scroll({ top: offsetTop, behavior: "smooth" });
    }
  }
};

export const handleProducts = () => {
  // Get a reference to the section you want to scroll to
  const section: HTMLElement | null = document.getElementById("products");
  const offsetTop = section !== null ? section.offsetTop - 106 : 0;

  // Scroll to the section
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
    if (window.innerWidth < 900) {
      window.scroll({ top: offsetTop, behavior: "smooth" });
    }
  }
};

export const handleContactUs = () => {
  // Get a reference to the section you want to scroll to
  const section: HTMLElement | null = document.getElementById("contact-us");
  const offsetTop = section !== null ? section.offsetTop - 106 : 0;

  // Scroll to the section
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
    if (window.innerWidth < 900) {
      window.scroll({ top: offsetTop, behavior: "smooth" });
    }
  }
};
