import React from "react";
import HomeBannerImage from "../../assets/images/shipping-industry-delivering-cargo-large-container-ship-generative-ai 1.jpg";
import { Box, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AboutBanner from "../../components/AboutUsBanner";
import ProductsSection from "../../components/ProductsSection";
import ContactUs from "../../components/ContactUs";
import Vector from "../../assets/images/Vector";
import { handleProducts } from "../../utils/utils";

const DesktopBackgroundImage = styled(Box)`
  & img {
    height: calc(100vh - 107px);
    width: 100%;
  }
`;

const MobileBackgroundImage = styled(Box)`
  & img {
    width: 100%;
    height: 280px;
  }
`;

const BannerContainer = styled(Box)`
  position: relative;
`;

const BannerTitleContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  background: #ffffff;
`;

const VectorTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const VectorContainer = styled(Box)`
  background: #f29100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>VK Brothers Exports | Home</title>
      </Helmet>

      <section>
        <BannerContainer
          sx={{ height: { xs: "280px", md: "calc(100vh - 107px)" } }}
        >
          <BannerTitleContainer
            sx={{
              padding: { xs: "15px 25px", md: "20px 50px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "48px" },
                lineHeight: { xs: "20px", md: "58px" },
                fontWeight: 700,
                color: "#000000",
              }}
            >
              Embrace Global Trade Expertise
            </Typography>
          </BannerTitleContainer>
          <VectorTextContainer
            sx={{
              padding: { xs: "10px", md: "32px" },
              gap: { xs: "10px", md: "28px" },
            }}
            onClick={handleProducts}
          >
            <Typography
              sx={{
                fontSize: { xs: "14px", md: "28px" },
                lineHeight: { xs: "20px", md: "38px" },
                fontWeight: 400,
                color: "#000000",
              }}
            >
              Explore Our All <br /> Products
            </Typography>
            <VectorContainer
              sx={{
                width: { xs: 35, md: 60 },
                height: { xs: 35, md: 60 },
              }}
            >
              <Vector />
            </VectorContainer>
          </VectorTextContainer>
          <DesktopBackgroundImage sx={{ display: { xs: "none", md: "block" } }}>
            <LazyLoadImage
              alt="banner-image"
              src={HomeBannerImage}
              height="calc(100vh - 107px)"
              width="100%"
              effect="blur"
            />
          </DesktopBackgroundImage>
          <MobileBackgroundImage sx={{ display: { xs: "flex", md: "none" } }}>
            <LazyLoadImage
              alt="banner-image"
              src={HomeBannerImage}
              height="auto"
              width="100%"
              effect="blur"
            />
          </MobileBackgroundImage>
        </BannerContainer>
      </section>

      <section id="about-us">
        <AboutBanner />
      </section>

      <section id="products">
        <ProductsSection />
      </section>

      <section id="contact-us">
        <ContactUs />
      </section>
    </div>
  );
};

export default Home;
