import React from "react";
import { Box, Button, styled } from "@mui/material";

interface IButtonComponent {
  label: string;
  onClick: () => void;
}

const ButtonComponent = ({ label, onClick }: IButtonComponent) => {
  const CommonButton = styled(Button)`
    border: 1px solid #000000;
    padding: 12px 24px;
    border-radius: 0px;
    text-transform: none;
    color: #000000;
    background: transparent;
    font-family: var(--font-secondary);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    &:hover {
      background: transparent;
    }
  `;

  return (
    <Box>
      <CommonButton onClick={onClick}>{label}</CommonButton>
    </Box>
  );
};

export default ButtonComponent;
