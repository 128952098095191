import React from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Box } from "@mui/material";
import "./assets/css/_global.css";
import NotFound from "./Pages/404page";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Box sx={{ paddingTop: { xs: "108px", md: "0px" } }}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default App;
