import React, { KeyboardEvent, useState } from "react";
import VKLogo from "../../assets/images/VKLogo";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ButtonComponent from "../Button";
import {
  handleAboutUs,
  handleContactUs,
  handleProducts,
} from "../../utils/utils";

const ListItems = styled(ListItem)`
  text-decoration: none;
  color: #979797 !important;
  font-size: 16px;
  font-family: var(--font-secondary);
  font-weight: 600;
  cursor: pointer;
`;

const ListContainer = styled(List)`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const NavLinkContainer = styled(Toolbar)`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flexgrow: 1;
  padding: 0px !important;
`;

const SideBarLogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent): void => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleHomeClick = () => {
    setDrawerOpen(false);
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header>
      <AppBar
        position="static"
        sx={{
          background: "#FFFFFF",
          padding: 0,
          boxShadow: "none",
          position: { xs: "fixed", md: "relative" },
          zIndex: "99",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ padding: { xs: "25px", md: "25px 50px" } }}
        >
          <Toolbar
            disableGutters
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ cursor: "pointer" }} onClick={handleHomeClick}>
              <VKLogo />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "row-reverse",
                  sm: "row-reverse",
                  md: "row",
                },
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "end",
                }}
              >
                <IconButton
                  className={"classes.menuButton"}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon
                    style={{ width: "40px", height: "40px", color: "#000000" }}
                  />
                </IconButton>
              </Box>
              <NavLinkContainer
                sx={{
                  display: { xs: "none", md: "flex", justifyContent: "end" },
                }}
              >
                <ListContainer>
                  <ListItems
                    sx={{
                      width: "max-content",
                      padding: { md: "8px", lg: "8px 16px" },
                      color: "#000000 !important",
                    }}
                    onClick={handleHomeClick}
                  >
                    Home
                  </ListItems>
                  <ListItems
                    sx={{
                      width: "max-content",
                      padding: { md: "8px", lg: "8px 16px" },
                    }}
                    onClick={handleAboutUs}
                  >
                    About Us
                  </ListItems>
                  <ListItems
                    sx={{
                      width: "max-content",
                      padding: { md: "8px", lg: "8px 16px" },
                    }}
                    onClick={handleProducts}
                  >
                    Products
                  </ListItems>
                </ListContainer>
              </NavLinkContainer>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <ButtonComponent label="Contact Us" onClick={handleContactUs} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          PaperProps={{ sx: { width: "75%", background: "#FFFFFF" } }}
        >
          <SideBarLogoContainer>
            <Box sx={{ cursor: "pointer" }} onClick={handleHomeClick}>
              <VKLogo />
            </Box>
          </SideBarLogoContainer>
          <Divider />
          <Box
            className={"classes.list"}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              <ListItems
                sx={{
                  width: "max-content",
                  padding: { md: "8px", lg: "8px 16px" },
                  color: "#000000 !important",
                }}
                onClick={handleHomeClick}
              >
                Home
              </ListItems>
              <ListItems
                sx={{
                  width: "max-content",
                  padding: { md: "8px", lg: "8px 16px" },
                }}
                onClick={handleAboutUs}
              >
                About Us
              </ListItems>
              <ListItems
                sx={{
                  width: "max-content",
                  padding: { md: "8px", lg: "8px 16px" },
                }}
                onClick={handleProducts}
              >
                Products
              </ListItems>
            </List>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "25px 16px",
            }}
          >
            <ButtonComponent
              label="Contact Us"
              onClick={() => {
                setDrawerOpen(false);
                handleContactUs();
              }}
            />
          </Box>
        </Drawer>
      </Box>
    </header>
  );
};

export default Header;
